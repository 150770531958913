<template>
  <div>
    <el-row
      style="
        display: flex;
        margin-bottom: 20px;
        font-size: 18px;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      "
    >
      <div>实验ID：{{ tableData.aiOperationTestCode }}</div>
      <div>实验名称：{{ tableData.aiOperationTestName }}</div>
      <div>总分：{{ tableData.aiScoreFull }}</div>
      <div>得分：{{ tableData.aiScore }}</div>
    </el-row>
    <el-row>
      <el-table
        :data="tableData.steps"
        :header-cell-style="{
          'text-align': 'center',
          backgroundColor: '#C0C4CC',
        }"
        stripe
        border
        highlight-current-row
      >
        <el-table-column
          align="center"
          type="index"
          label="序号"
          width="56"
        ></el-table-column>
        <el-table-column align="center" type="expand" label="详情" width="56">
          <template slot-scope="points">
            <el-table
              :data="points.row.points"
              :header-cell-style="{
                'text-align': 'center',
                backgroundColor: '#E4E7ED',
              }"
              border
              highlight-current-row
            >
              <el-table-column
                align="center"
                label=""
                width="55"
              ></el-table-column>
              <el-table-column
                align="center"
                type="expand"
                label="详情"
                width="56"
              >
                <template slot-scope="pointEvents">
                  <el-table
                    :data="pointEvents.row.pointEvents"
                    :header-cell-style="{
                      'text-align': 'center',
                      backgroundColor: '#EBEEF5',
                    }"
                    border
                    highlight-current-row
                  >
                    <el-table-column
                      align="center"
                      label=""
                      width="55"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      type="expand"
                      label="详情"
                      width="56"
                    >
                      <template slot-scope="aiEvents">
                        <el-table
                          :data="aiEvents.row.aiEvents"
                          :header-cell-style="{
                            'text-align': 'center',
                            backgroundColor: '#F2F6FC',
                          }"
                          border
                          highlight-current-row
                        >
                          <el-table-column
                            align="center"
                            label=""
                            width="110"
                          ></el-table-column>
                          <el-table-column
                            align="center"
                            show-overflow-tooltip
                            property="aiEventCode"
                            label="AI事件ID"
                            width="111"
                          >
                            <template slot-scope="text">
                              <span style="color: #ff0000">
                                {{ text.row.aiEventCode }}</span
                              >
                            </template>
                          </el-table-column>
                          <el-table-column
                            align="center"
                            property="aiScoreFull"
                            label="AI事件"
                            width="110"
                          >
                            <template slot-scope="scoped">
                              <span
                                v-if="!scoped.row.aiPointType"
                                style="color: #1890ff"
                                >操作AI事件</span
                              >
                              <span v-else style="color: #67c23a"
                                >报告AI事件</span
                              >
                            </template>
                          </el-table-column>
                          <el-table-column
                            show-overflow-tooltip
                            property="aiEventContent"
                            label="评分项"
                          ></el-table-column>

                          <el-table-column
                            align="center"
                            property="aiScoreFull"
                            label="总分"
                            width="56"
                          ></el-table-column>
                          <el-table-column
                            align="center"
                            property="aiScore"
                            label="得分"
                            width="55"
                          >
                            <template slot-scope="color">
                              <span
                                :style="
                                  color.row.aiScoreFull > color.row.aiScore
                                    ? 'color: #E6A23C'
                                    : ''
                                "
                                >{{ color.row.aiScore }}</span
                              >
                            </template>
                          </el-table-column>
                        </el-table>
                      </template>
                    </el-table-column>
                    <el-table-column
                      align="center"
                      show-overflow-tooltip
                      property="aiPointEventCode"
                      label="得分点事件ID"
                      width="111"
                    >
                      <template slot-scope="text">
                        <span style="color: #00b0f0">
                          {{ text.row.aiPointEventCode }}</span
                        >
                      </template>
                    </el-table-column>
                    <el-table-column
                      align="center"
                      property="aiScoreFull"
                      label="得分点事件"
                      width="110"
                    >
                      <template slot-scope="scoped">
                        <span
                          v-if="!scoped.row.aiPointType"
                          style="color: #1890ff"
                          >操作事件</span
                        >
                        <span v-else style="color: #67c23a">报告事件</span>
                      </template>
                    </el-table-column>
                    <el-table-column
                      show-overflow-tooltip
                      property="aiPointEventContent"
                      label="评分项"
                    ></el-table-column>

                    <el-table-column
                      align="center"
                      property="aiScoreFull"
                      label="总分"
                      width="56"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      property="aiScore"
                      label="得分"
                      width="55"
                    >
                      <template slot-scope="color">
                        <span
                          :style="
                            color.row.aiScoreFull > color.row.aiScore
                              ? 'color: #E6A23C'
                              : ''
                          "
                          >{{ color.row.aiScore }}</span
                        >
                      </template>
                    </el-table-column>
                  </el-table>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                show-overflow-tooltip
                property="aiPointCode"
                label="得分点ID"
                width="111"
              >
                <template slot-scope="text">
                  <span style="color: #ffc000">
                    {{ text.row.aiPointCode }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                property="aiScoreFull"
                label="得分点"
                width="110"
              >
                <template slot-scope="scoped">
                  <span v-if="!scoped.row.aiPointType" style="color: #1890ff"
                    >操作得分点</span
                  >
                  <span v-else style="color: #67c23a">报告得分点</span>
                </template>
              </el-table-column>
              <el-table-column
                show-overflow-tooltip
                property="aiPointContent"
                label="评分项"
              ></el-table-column>

              <el-table-column
                align="center"
                property="aiScoreFull"
                label="总分"
                width="56"
              ></el-table-column>
              <el-table-column
                align="center"
                property="aiScore"
                label="得分"
                width="55"
              >
                <template slot-scope="color">
                  <span
                    :style="
                      color.row.aiScoreFull > color.row.aiScore
                        ? 'color: #E6A23C'
                        : ''
                    "
                    >{{ color.row.aiScore }}</span
                  >
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          property="aiOperationItemCode"
          label="ID"
          width="110"
          style="color: #70ad47"
        >
          <template slot-scope="text">
            <span style="color: #70ad47">
              {{ text.row.aiOperationItemCode }}</span
            >
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          property="aiOperationItemContent"
          label="步骤项"
          width="110"
        >
          <template slot-scope="scoped">
            <span :style="scoped.row.aiQuestionItemType | stepsFilterColor">
              {{ scoped.row.aiQuestionItemType | stepsFilterText }}</span
            >
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          property="aiOperationItemName"
          label="评分项"
        ></el-table-column>
        <el-table-column
          align="center"
          width="56"
          property="aiScoreFull"
          label="总分"
        ></el-table-column>
        <el-table-column
          align="center"
          width="56"
          property="aiScore"
          label="得分"
        >
          <template slot-scope="color">
            <span
              :style="
                color.row.aiScoreFull > color.row.aiScore
                  ? 'color: #E6A23C'
                  : ''
              "
              >{{ color.row.aiScore }}</span
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
  </div>
</template>

<script>
import { Loading } from 'element-ui'
import { getOperationConfig } from '@/api/scoreAI/scoreDisposition.js'
export default {
  name: 'scoreDisposition',
  data() {
    return {
      aiOperationQuestionId: '',
      tableData: [],
    }
  },
  mounted() {
    this.aiOperationQuestionId = Number(this.$route.query.aiOperationQuestionId)
    this.getOperationConfigFunc(this.aiOperationQuestionId)
  },
  methods: {
    getOperationConfigFunc(val) {
      getOperationConfig(val)
        .then((res) => {
          if (res.success) {
            this.tableData = res.data
          }
        })
        .catch((err) => {})
    },
  },
  filters: {
    subjectFilter(key, font = '') {
      switch (key) {
        case 1:
          return `物理${font}`
        case 2:
          return `生物${font}`
        case 3:
          return `化学${font}`
        default:
          return `未知学科${font}`
      }
    },
    stepsFilterText(key) {
      switch (key) {
        case 0:
          return `操作步骤`
        case 1:
          return `报告步骤`
        case 2:
          return `混合步骤`
        default:
          return ``
      }
    },
    stepsFilterColor(key) {
      switch (key) {
        case 0:
          return `color: #1890ff`
        case 1:
          return `color: #67c23a`
        default:
          return ``
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-table__expanded-cell {
  padding: 0;
}
</style>
