var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-row',{staticStyle:{"display":"flex","margin-bottom":"20px","font-size":"18px","align-items":"center","justify-content":"space-between","width":"100%"}},[_c('div',[_vm._v("实验ID："+_vm._s(_vm.tableData.aiOperationTestCode))]),_c('div',[_vm._v("实验名称："+_vm._s(_vm.tableData.aiOperationTestName))]),_c('div',[_vm._v("总分："+_vm._s(_vm.tableData.aiScoreFull))]),_c('div',[_vm._v("得分："+_vm._s(_vm.tableData.aiScore))])]),_c('el-row',[_c('el-table',{attrs:{"data":_vm.tableData.steps,"header-cell-style":{
        'text-align': 'center',
        backgroundColor: '#C0C4CC',
      },"stripe":"","border":"","highlight-current-row":""}},[_c('el-table-column',{attrs:{"align":"center","type":"index","label":"序号","width":"56"}}),_c('el-table-column',{attrs:{"align":"center","type":"expand","label":"详情","width":"56"},scopedSlots:_vm._u([{key:"default",fn:function(points){return [_c('el-table',{attrs:{"data":points.row.points,"header-cell-style":{
              'text-align': 'center',
              backgroundColor: '#E4E7ED',
            },"border":"","highlight-current-row":""}},[_c('el-table-column',{attrs:{"align":"center","label":"","width":"55"}}),_c('el-table-column',{attrs:{"align":"center","type":"expand","label":"详情","width":"56"},scopedSlots:_vm._u([{key:"default",fn:function(pointEvents){return [_c('el-table',{attrs:{"data":pointEvents.row.pointEvents,"header-cell-style":{
                    'text-align': 'center',
                    backgroundColor: '#EBEEF5',
                  },"border":"","highlight-current-row":""}},[_c('el-table-column',{attrs:{"align":"center","label":"","width":"55"}}),_c('el-table-column',{attrs:{"align":"center","type":"expand","label":"详情","width":"56"},scopedSlots:_vm._u([{key:"default",fn:function(aiEvents){return [_c('el-table',{attrs:{"data":aiEvents.row.aiEvents,"header-cell-style":{
                          'text-align': 'center',
                          backgroundColor: '#F2F6FC',
                        },"border":"","highlight-current-row":""}},[_c('el-table-column',{attrs:{"align":"center","label":"","width":"110"}}),_c('el-table-column',{attrs:{"align":"center","show-overflow-tooltip":"","property":"aiEventCode","label":"AI事件ID","width":"111"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('span',{staticStyle:{"color":"#ff0000"}},[_vm._v(" "+_vm._s(text.row.aiEventCode))])]}}],null,true)}),_c('el-table-column',{attrs:{"align":"center","property":"aiScoreFull","label":"AI事件","width":"110"},scopedSlots:_vm._u([{key:"default",fn:function(scoped){return [(!scoped.row.aiPointType)?_c('span',{staticStyle:{"color":"#1890ff"}},[_vm._v("操作AI事件")]):_c('span',{staticStyle:{"color":"#67c23a"}},[_vm._v("报告AI事件")])]}}],null,true)}),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","property":"aiEventContent","label":"评分项"}}),_c('el-table-column',{attrs:{"align":"center","property":"aiScoreFull","label":"总分","width":"56"}}),_c('el-table-column',{attrs:{"align":"center","property":"aiScore","label":"得分","width":"55"},scopedSlots:_vm._u([{key:"default",fn:function(color){return [_c('span',{style:(color.row.aiScoreFull > color.row.aiScore
                                  ? 'color: #E6A23C'
                                  : '')},[_vm._v(_vm._s(color.row.aiScore))])]}}],null,true)})],1)]}}],null,true)}),_c('el-table-column',{attrs:{"align":"center","show-overflow-tooltip":"","property":"aiPointEventCode","label":"得分点事件ID","width":"111"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('span',{staticStyle:{"color":"#00b0f0"}},[_vm._v(" "+_vm._s(text.row.aiPointEventCode))])]}}],null,true)}),_c('el-table-column',{attrs:{"align":"center","property":"aiScoreFull","label":"得分点事件","width":"110"},scopedSlots:_vm._u([{key:"default",fn:function(scoped){return [(!scoped.row.aiPointType)?_c('span',{staticStyle:{"color":"#1890ff"}},[_vm._v("操作事件")]):_c('span',{staticStyle:{"color":"#67c23a"}},[_vm._v("报告事件")])]}}],null,true)}),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","property":"aiPointEventContent","label":"评分项"}}),_c('el-table-column',{attrs:{"align":"center","property":"aiScoreFull","label":"总分","width":"56"}}),_c('el-table-column',{attrs:{"align":"center","property":"aiScore","label":"得分","width":"55"},scopedSlots:_vm._u([{key:"default",fn:function(color){return [_c('span',{style:(color.row.aiScoreFull > color.row.aiScore
                            ? 'color: #E6A23C'
                            : '')},[_vm._v(_vm._s(color.row.aiScore))])]}}],null,true)})],1)]}}],null,true)}),_c('el-table-column',{attrs:{"align":"center","show-overflow-tooltip":"","property":"aiPointCode","label":"得分点ID","width":"111"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('span',{staticStyle:{"color":"#ffc000"}},[_vm._v(" "+_vm._s(text.row.aiPointCode))])]}}],null,true)}),_c('el-table-column',{attrs:{"align":"center","property":"aiScoreFull","label":"得分点","width":"110"},scopedSlots:_vm._u([{key:"default",fn:function(scoped){return [(!scoped.row.aiPointType)?_c('span',{staticStyle:{"color":"#1890ff"}},[_vm._v("操作得分点")]):_c('span',{staticStyle:{"color":"#67c23a"}},[_vm._v("报告得分点")])]}}],null,true)}),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","property":"aiPointContent","label":"评分项"}}),_c('el-table-column',{attrs:{"align":"center","property":"aiScoreFull","label":"总分","width":"56"}}),_c('el-table-column',{attrs:{"align":"center","property":"aiScore","label":"得分","width":"55"},scopedSlots:_vm._u([{key:"default",fn:function(color){return [_c('span',{style:(color.row.aiScoreFull > color.row.aiScore
                      ? 'color: #E6A23C'
                      : '')},[_vm._v(_vm._s(color.row.aiScore))])]}}],null,true)})],1)]}}])}),_c('el-table-column',{staticStyle:{"color":"#70ad47"},attrs:{"align":"center","property":"aiOperationItemCode","label":"ID","width":"110"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('span',{staticStyle:{"color":"#70ad47"}},[_vm._v(" "+_vm._s(text.row.aiOperationItemCode))])]}}])}),_c('el-table-column',{attrs:{"align":"center","property":"aiOperationItemContent","label":"步骤项","width":"110"},scopedSlots:_vm._u([{key:"default",fn:function(scoped){return [_c('span',{style:(_vm._f("stepsFilterColor")(scoped.row.aiQuestionItemType))},[_vm._v(" "+_vm._s(_vm._f("stepsFilterText")(scoped.row.aiQuestionItemType)))])]}}])}),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","property":"aiOperationItemName","label":"评分项"}}),_c('el-table-column',{attrs:{"align":"center","width":"56","property":"aiScoreFull","label":"总分"}}),_c('el-table-column',{attrs:{"align":"center","width":"56","property":"aiScore","label":"得分"},scopedSlots:_vm._u([{key:"default",fn:function(color){return [_c('span',{style:(color.row.aiScoreFull > color.row.aiScore
                ? 'color: #E6A23C'
                : '')},[_vm._v(_vm._s(color.row.aiScore))])]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }